export const PRODUCT_CATEGORIES = [
  "Electronics",
  "Cameras",
  "Laptops",
  "Accessories",
  "Headphones",
  "Food",
  "Books",
  "Sports",
  "Outdoor",
  "Home",
];
